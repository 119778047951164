import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";


function Search() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const [addToCartModal, setAddToCartModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [abortController, setAbortController] = useState(null); // AbortController 상태 추가

  const goBack = () => {
    if (abortController) {
      abortController.abort(); // 검색 중단
    }
    navigate(-1); // 뒤로 가기
  };
  
  useEffect(() => {
    return () => { // 브라우저 뒤로가기 버튼 에러 해결 코드
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
      setIsLoading("afterSearching");
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("query") || "";
    setSearchQuery(query);
    if (query) {
      handleSearch(query);
    }
  }, [location.search]);

  
  const handleSearch = async (query = searchQuery) => {
    if (abortController) {
      abortController.abort(); // 이전 검색이 진행 중일 경우 중단
    }
  
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
  
    try {
      const res = await fetch(
        `https://us-central1-stories-e89af.cloudfunctions.net/start/api/listing/search/get?query=${query}`,
        {
          credentials: "include",
          signal: newAbortController.signal,
        }
      );
  
      if (!res.ok) throw new Error("검색 요청 실패");
  
      let data = await res.json();
  
      // reviewCount 많은 순으로 정렬
      data = data.sort((a, b) => b.reviewCount - a.reviewCount);
  
      setSearchResult(data);
      setIsLoading(false);
  
      // 검색어를 URL에 쿼리 매개변수로 추가
      navigate(`/search?query=${query}`, { replace: true });
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("검색 요청이 중단되었습니다.");
      } else {
        alert("검색 중 오류가 발생했습니다.");
      }
    }
  };
  
  

  const handleAddToCartClick = async (e, listing) => {
    e.preventDefault();

    if (!currentUser) {
      navigate("/signin");
      return;
    }

    setAddToCartModal(true);

    
    const cartFormData = {
      user_id: currentUser._id,
      products: [
        {
          product_id: listing._id,
        },
      ],
    };

    try {
      const res = await fetch(
        "https://us-central1-stories-e89af.cloudfunctions.net/start/api/cart/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cartFormData),
          credentials: "include",
        }
      );

      const data = await res.json();

      if (res.ok) {
        setTimeout(() => {
          setAddToCartModal(false);
        }, 2000);
      } else {
        alert("장바구니 추가에 실패했습니다.");
      }
    } catch (error) {
      alert("오류가 발생했습니다.");
    }
  };

  return (
    <div className="search-container">
      {addToCartModal ? (
        <div className="add-to-cart-modal">장바구니에 추가되었습니다.</div>
      ) : null}
      <div className="backicon-container">
        <img onClick={goBack} src="back.svg" className="backicon-search" />
      </div>
      <div className="search-box-container">
        <input
          type="text"
          placeholder="검색어를 입력하세요"
          className="search-box"
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyDown={handleKeyPress}
        />
        <img
          onClick={() => {
            setSearchQuery("");
          }}
          src="deleteicon.svg"
          className="delete-icon"
        />
      </div>

      <div className="search-ProductList">
        {isLoading == "afterSearching"
          ? Array.from({ length: 10 }).map((_, index) => (
              <div className="home-product" key={index}>
                <div className="b1">
                  <div className="skeleton skeleton-product-thumbnail"> </div>
                </div>
                <div className="b2">
                  <div className="product-container">
                    <div className="skeleton skeleton-product-name"> </div>
                    <div className="skeleton skeleton-who-wrote-this"> </div>
                    <div className="skeleton skeleton-detail-review-rating-star"></div>
                    <div className="skeleton skeleton-product-price"> </div>
                    <div className="skeleton skeleton-add-to-cart"> </div>
                  </div>
                </div>
              </div>
            ))
          : searchResult.map((listing, index) => (
              <Link to={`/detail/${listing._id}`} key={index}>
                <div className="search-result-container">
                  <div className="search-b1">
                    <img src={listing.imageurl} className="product-thumbnail" />
                  </div>
                  <div className="search-b2">
                    <div className="product-container">
                      <div className="product-name">{listing.personname}</div>
                      <div className="who-wrote-this">
                        <div>{listing.brandname} 창립자의 이야기</div>
                      </div>
                      <div className="detail-review-rating-star">
                        <div>
                          {[1, 2, 3, 4, 5].map((star) => (
                            <img
                              key={star}
                              src={
                                listing.rating >= star
                                  ? "/bigstarfilled.svg"
                                  : listing.rating >= star - 0.5
                                  ? "/halfstar2.svg"
                                  : "/bigstarempty2.svg"
                              }
                              alt="star rating"
                            />
                          ))}
                        </div>
                        <div className="rating">{listing.reviewCount}</div>
                      </div>
                      <div className="product-price">{listing.price}원</div>
                      <button
                        className="add-to-cart"
                        onClick={(e) => handleAddToCartClick(e, listing)}
                      >
                        장바구니 담기
                      </button>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
      </div>
    </div>
  );
}

export default Search;

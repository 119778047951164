import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Menu() {

  const navigate = useNavigate(); // useNavigate 훅 사용

  const goBack = () => {
    navigate("/"); // 이전 페이지로 이동
  };

  return (
    <div>
      <div className="menu-close-container">
        <img onClick={goBack} src="closeicon2.svg" className="menucloseicon" />
      </div>

      <div className="menu-container">
      <Link to="/menu/사업" className="menu-container1">
        <div>전체보기</div>
        <div>사업</div>
        </Link>
        <div className="menu-investment">투자 (준비중)</div>
        <div className="menu-job">직업 (준비중)</div>
      </div>

    </div>
  );
}

export default Menu;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  signInStart,
  signInFailure,
  signInSuccess,
} from "../redux/user/userSlice";

function Signin() {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});
  const navigate = useNavigate(); // useNavigate 훅 사용
  const dispatch = useDispatch();

  const goBack = () => {
    navigate("/") // 홈 이동
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    setError({}); // 입력 값이 변경될 때마다 오류 상태 초기화
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await fetch("https://us-central1-stories-e89af.cloudfunctions.net/start/api/auth/signin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include", // 서로 다른 도메인의 경우 오류가 발생하는데 이 코드를 통해 쿠키가 잘 오갈 수 있도록 함
      body: JSON.stringify(formData),
    });
    // 서버의 응답을 받는 것이며, 상태에 저장해서 UI로 사용가능
    // 응답 받지 않아도 자동으로 네트워크 탭 응답에서 확인 가능
    const data = await res.json();
    setError(data);
    if (data.success === false) {
      dispatch(signInFailure(data.message)); // 만약 로그인이 실패했다면, 리덕스 슬라이스 상태 실패 (서버 메세지)로 변경
    } else {
      dispatch(signInSuccess(data));
      navigate("/");
    }
  };

  return (
    <div className="signin-container">
      <div className="close-container">
        <img onClick={goBack} src="closeicon.svg" className="closeicon" />
      </div>

      <div className="logo-container">
        <img src="stories.svg" className="signin-logo" />
      </div>

      <form className="idpw-form" onSubmit={handleSubmit}>
        <input
          className={
            error.message === "존재하지 않는 아이디입니다."
              ? "signin-id-error"
              : "signin-id"
          }
          type="text"
          placeholder="아이디"
          id="id"
          onChange={handleChange}
        />
        {error.message === "존재하지 않는 아이디입니다." ? (
          <span className="id-error-message">{error.message}</span>
        ) : null}
        <input
          className={
            error.message === "잘못된 비밀번호 입니다."
              ? "signin-pw-error"
              : "signin-pw"
          }
          type="password"
          placeholder="비밀번호"
          id="password"
          onChange={handleChange}
        />
        {error.message === "잘못된 비밀번호 입니다." ? (
          <span className="pw-error-message">{error.message}</span>
        ) : null}
        <button type="submit" className="signin-submit-btn">
          로그인
        </button>
      </form>

      <div className="idpw-find-container">
        <Link to={"/signup"}>
          <button className="signup">회원가입</button>
        </Link>
        <Link to={"/forgotpassword"}>
          <button className="pw-find">비밀번호 찾기</button>
        </Link>
      </div>

    </div>
  );
}

export default Signin;

import React from 'react'
import { useNavigate } from 'react-router-dom';

function Chagepassword() {

    const navigate = useNavigate(); // useNavigate 훅 사용

    const goBack = () => {
      navigate(-1); // 이전 페이지로 이동
    }

  return (
    <div>
        <div className="changepassword-nav-container">
            <img onClick={ goBack } src="back.svg" className="backicon"/>
            <div className="change-text">비밀번호 변경</div>
        </div>

        <div className="complete-box">
        <input type="text" placeholder="새 비밀번호" className="change-box-pw1" />
        <input type="text" placeholder="비밀번호 재확인" className="change-box-pw2" />
        </div>
        <button type="submit" className="complete-btn">완료</button>
    </div>
  )
}

export default Chagepassword
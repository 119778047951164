import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

function Reviewwrite() {
  const [rating, setRating] = useState(0); // 별점 상태 관리
  const [content, setContent] = useState(""); // 리뷰 내용 상태 관리
  const { currentUser } = useSelector((state) => state.user);
  const [reviewSuccessModal, setReviewSuccessModal] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  // 별점을 클릭했을 때 호출되는 함수
  const handleRatingClick = (value) => {
    setRating(value); // 별점 상태 업데이트
  };

  // 별점에 따라 채워진 이미지와 빈 이미지 반환하는 함수
  const getStarImage = (index) => {
    return index <= rating ? "/bigstarfilled.svg" : "/bigstarempty.svg";
  };

  const handleSubmit = async () => {
    if (rating === 0 || content.trim() === "") {
      return;
    }

    setReviewSuccessModal(true);

    const reviewData = {
      product_id: params.id,
      reviews: [
        {
          user_id: currentUser._id,
          profileimg: currentUser.profileimg,
          nickname: currentUser.nickname,
          content: content,
          rating: rating,
        },
      ],
    };
    const res = await fetch("https://us-central1-stories-e89af.cloudfunctions.net/start/api/review/post", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(reviewData),
    });
    const data = await res.json();
    if (res.ok) {
      setTimeout(() => {
        navigate("/purchasehistory");
      }, 2000);
    }
  };

  return (
    <div>
      {reviewSuccessModal ? (
        <div className="review-success-modal">후기가 등록되었습니다.</div>
      ) : null}
      <div>
        <div className="review-nav-container">
          <img src="/back.svg" onClick={() => navigate("/purchasehistory")} className="backicon" />
        </div>
        <div className="review-score-container">
          {[1, 2, 3, 4, 5].map((value) => (
            <div
              key={value}
              className="review-write-star-div"
              onClick={() => handleRatingClick(value)} // 클릭 시 별점 설정
            >
              <img src={getStarImage(value)} className="review-write-star" />
            </div>
          ))}
        </div>
        <textarea
          placeholder="내용을 입력하세요"
          className="review-write-textarea"
          value={content}
          onChange={(e) => setContent(e.target.value)} // 텍스트 변경 시 상태 업데이트
        />
        </div>
        <button className="review-write-completed-btn" onClick={handleSubmit}>
          완료
        </button>
    </div>
  );
}

export default Reviewwrite;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const savedScrollY = sessionStorage.getItem(`scrollPosition-${pathname}`);

    // 페이지가 처음 로드될 때 저장된 스크롤 위치로 이동
    if (savedScrollY) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(savedScrollY, 10));
      }, 900)
    } else {
      // 저장된 위치가 없으면 상단으로 스크롤
      setTimeout(() => {
        window.scrollTo(0, 0);
      },700)
    }

    // 스크롤 위치 저장
    const handleScroll = () => {
      sessionStorage.setItem(`scrollPosition-${pathname}`, window.scrollY);
    };

    // 스크롤 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pathname]);

  return null;
};

export default ScrollToTop;

function NotFound() {
    return (
      <div>
        <h1 style={{color : "#222222", fontSize: "24px", marginLeft : "10px"}}>404 - 페이지를 찾을 수 없습니다</h1>
        <a href="/" style={{ textDecoration: 'underline', color : "#222222", marginLeft : "10px"}}>스토리즈 바로가기</a>
      </div>
    );
  }
  
  export default NotFound;
  
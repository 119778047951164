import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD0cN6KbayUStZ7qdrjnluBRzIgxQjJDwQ",
  authDomain: "stories-e89af.firebaseapp.com",
  projectId: "stories-e89af",
  storageBucket: "stories-e89af.appspot.com",
  messagingSenderId: "607273575502",
  appId: "1:607273575502:web:6e1e520bd684aaf8e5cf1f",
  measurementId: "G-X4CDVHDBXC"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };

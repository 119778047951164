import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Completecheckout() {
  const params = useParams();
  const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const productIdsArray = params.product_id.split(",");
  const productsArray = productIdsArray.map((productId) => ({
    product_id: productId,
  }));

  const fetchCheckout = async () => {
    const checkoutFormData = {
      user_id: currentUser._id, // 현재 사용자 ID
      products: productsArray, // 상품 객체 배열을 products에 할당
    };

    const res = await fetch("https://us-central1-stories-e89af.cloudfunctions.net/start/api/checkout/post", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(checkoutFormData),
      credentials: "include",
    });
  };

  useEffect(() => {
    fetchCheckout();
  }, []);

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="complete-checkout-main-container" onClick={handleClick}>
      <div className="complete-checkout-container" >
        <div className="complete-checkout-text">결제가 완료되었습니다.</div>
      </div>
      <div className="complete-checkout-confirm-text">
        화면을 터치하면 홈으로 이동합니다.
      </div>
    </div>
  );
}

export default Completecheckout;

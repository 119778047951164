import React from "react";

function Hello() {
  return (
    <div>
      <h3 className="abc">바퀴벌레 생존력</h3>
      <p className="abc">
        돈이 없어서 약 100권의 책을 읽고 반품하고,
        <br></br> 매일 10km를 걸어다니는 저의 열정에 투자하세요
      </p>

      <img src="hello.svg" className="helloimg" />
    </div>
  );
}

export default Hello;

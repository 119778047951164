import { useSelector } from "react-redux";
import { useRef, useState, useEffect } from "react";
import { app } from "../firebase";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import imageCompression from 'browser-image-compression';

import {
  updateUserSuccess,
  updateUserFailure,
  signInSuccess,
} from "../redux/user/userSlice";

function Profileedit() {
  const { currentUser } = useSelector((state) => state.user);
  const fileRef = useRef(null);
  const [file, setFile] = useState(undefined);
  const [formData, setFormData] = useState({});
  const [successModal, setSuccessModal] = useState(false);
  const [errorMessageModal, setErrorMessageModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [nickname, setNickname] = useState(currentUser.nickname || "");
  const [isLoading, setIsLoading] = useState(undefined);

  // Image compression options
  const compressionOptions = {
    maxWidthOrHeight: 800,  // Max width/height of the compressed image
    useWebWorker: true,      // Using Web Worker for better performance
    maxSizeMB: 1,           // Max file size after compression (in MB)
  };

  useEffect(() => {
    if (file) {
      setIsLoading("upLoading");
      compressAndUploadImage(file);
    }
  }, [file]);

  const compressAndUploadImage = async (file) => {
    try {
      // Compress image
      const compressedFile = await imageCompression(file, compressionOptions);
      handleFileUpload(compressedFile);
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };

  const handleFileUpload = (file) => {
    const storage = getStorage(app);
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      () => {
        // 무시해도 되는 콜백 코드
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) =>
          setFormData({ ...formData, profileimg: downloadURL })
        );
        setIsLoading(false);
      }
    );
  };

  const handleNicknameChange = (e) => {
    const newNickname = e.target.value;
    setNickname(newNickname);
    setFormData((prevData) => ({ ...prevData, nickname: newNickname }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(
        `https://us-central1-stories-e89af.cloudfunctions.net/start/api/user/update/${currentUser._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(formData),
        }
      );
      const data = await res.json();
      if (res.ok) {
        setSuccessModal(true);
        setTimeout(() => {
          dispatch(updateUserSuccess(data));
          navigate("/profile");
        }, 3000);
      } else {
        setErrorMessage(data);
        setErrorMessageModal(data);
        setTimeout(() => {
          setErrorMessageModal("");
        }, 3000);
      }
    } catch (error) {}
  };

  console.log(formData);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {errorMessageModal ? (
          <div className="profileedit-error-modal">{errorMessage}</div>
        ) : null}
        {successModal ? (
          <div className="profileedit-success-modal">
            변경이 완료되었습니다.
          </div>
        ) : null}
        <div className="profileedit-nav-container">
          <div
            onClick={() => navigate("/profile")}
            className="profileedit-close"
          >
            닫기
          </div>
          <div>프로필 수정</div>
          <button className="profileeditpage-complete-btn" type="submit">
            완료
          </button>
        </div>
        <input
          onChange={(e) => setFile(e.target.files[0])} // 현재 이벤트(e)가 발생된(클릭한 이 인풋)의 첫번째 파일로 변경
          type="file"
          ref={fileRef}
          hidden
          accept="image/*"
        />
        <div className="profileedit-noprofileicon-container">
          {isLoading == "upLoading" ? (
            <div className="skeleton skeleton-profileicon-profileeditpage"></div>
          ) : (
            <img
              onClick={() => fileRef.current.click()}
              src={formData.profileimg || currentUser.profileimg}
              className="profileicon-profileeditpage"
            />
          )}
        </div>
        <div className="profileedit-container">
          <img src="profileedit.svg" className="photo-img-edit-text" />
        </div>
        <input
          className="profileedit-name"
          value={nickname}
          onChange={handleNicknameChange}
        ></input>
      </form>
    </div>
  );
}

export default Profileedit;

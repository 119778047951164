import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../firebase";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  confirmVerificationCode,
} from "firebase/auth";

function Forgotpassword2() {
  const navigate = useNavigate(); // useNavigate 훅 사용
  const params = useParams();
  const [formData, setFormData] = useState({});
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [smsSendSuccess, setSmsSendSuccess] = useState("");
  const [smsConfirmed, setSmsConfirmed] = useState("");
  const [error, setError] = useState({});
  const [phoneData, setPhoneData] = useState("");

  auth.languageCode = "ko";

  const goBack = () => {
    navigate(-1); // 이전 페이지로 이동
  };

  const handleChange = (e) => {
    setFormData({ ...formData, phonenumber: e.target.value }); // 입력값을 formData에 저장
    if (error) {
      setError(""); // 입력값이 변경될 때 error를 초기화
    }
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA가 해결되었을 때 콜백
      },
    });
  };

  useEffect(() => {
    const getPhoneData = async () => {
      const res = await fetch(
        `https://us-central1-stories-e89af.cloudfunctions.net/start/api/auth/forgotpassword2/get/${params.id}`,
        {
          credentials: "include",
        }
      );
      const data = await res.json();
      setPhoneData(data); // params 아이디의 휴대폰번호
    };
    getPhoneData();
  }, []);

  const handleSend = () => {
    if (!formData.phonenumber) {
      return; // 미입력
    }

    if (phoneData !== formData.phonenumber) {
      setSmsSendSuccess("등록된 휴대폰 번호와 일치하지 않습니다.");
      return; // 조건이 참일 경우 함수 종료
    }  

    setSmsSendSuccess("success");

    generateRecaptcha(); // reCAPTCHA 생성

    const phone = `+82${formData.phonenumber.slice(1)}`; // 국제 전화번호 형식으로 변환
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        // SMS가 전송된 후 처리
        window.confirmationResult = confirmationResult;
        setConfirmationResult(confirmationResult);
        console.log(confirmationResult);
        console.log("인증번호가 전송되었습니다.");
      })
      .catch((error) => {
        // SMS 전송 실패 시 처리
        console.log("인증번호 전송에 실패했습니다. 다시 시도해 주세요.");
        setSmsSendSuccess("failure");
      });
  };

  const handleVerifyCode = () => {
    if (!verificationCode) {
      setError({ message: "인증번호를 입력해 주세요" });
      return;
    }

    if (!confirmationResult) {
      setError({ message: "인증번호 전송이 완료되지 않았습니다." });
      return;
    }

    confirmationResult
      .confirm(verificationCode)
      .then((result) => {
        // 인증 성공
        console.log("인증이 완료되었습니다.");
        setSmsConfirmed("success");
        setSmsSendSuccess("");
      })
      .catch((error) => {
        // 인증 실패
        console.log("인증번호가 일치하지 않습니다. 다시 시도해 주세요.");
        setSmsConfirmed("failure");
      });
  };

  // 인증번호가 6자리가 되면 자동으로 인증 처리
  useEffect(() => {
    if (verificationCode.length === 6) {
      handleVerifyCode();
    }
  }, [verificationCode]);

  // 숫자만 입력되도록 하고, 최대 6자리로 제한
  const handleCodeChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 6) {
      setVerificationCode(value);
    }
    if (value === "") {
      setSmsConfirmed("default"); // 입력값이 비었을 때 기본 상태로 변경
    }
  };

  const nextPage = () => {
    if (smsConfirmed !== "success") {
      return;
    }
    navigate(`/forgotpassword3/${params.id}`);
  };

  return (
    <div>
      <div className="forgotpassword-nav-container">
        <img onClick={goBack} src="/back.svg" className="backicon" />
      </div>
      <div className="forgot-box">
        <div className="forgot-text">휴대폰 인증</div>
        <div className="phone-number-container">
          <input
            type="tel"
            id="phonenumber"
            placeholder="휴대폰 번호  (‘-’ 제외)"
            className="phone-number"
            onChange={handleChange}
          />
          {formData.phonenumber ? (
            <img
              src="/sendsmsicon.svg"
              onClick={handleSend}
              className="forgotpassword2-sms-send"
            />
          ) : null}

          {smsSendSuccess == "등록된 휴대폰 번호와 일치하지 않습니다." ? (
            <span className="signup-send-error-message">
              등록된 휴대폰 번호와 일치하지 않습니다.
            </span>
          ) : smsSendSuccess == "success" ? (
            <span className="signup-send-success-message">
              인증번호가 전송되었습니다.
            </span>
          ) : smsSendSuccess == "failure" ? (
            <span className="signup-send-error-message">
              인증번호 전송에 실패했습니다. 다시 시도해 주세요.
            </span>
          ) : null}
        </div>
        <input
          type="text"
          placeholder="인증번호 입력"
          className={
            smsConfirmed === "failure" ? "verify-code-failure" : "verify-code"
          }
          value={verificationCode}
          onChange={handleCodeChange}
        />
        {smsConfirmed == "success" ? (
          <div className="sms-confirmed-success">인증이 완료되었습니다.</div>
        ) : smsConfirmed == "failure" ? (
          <div className="sms-confirmed-failure">
            인증번호가 일치하지 않습니다.
          </div>
        ) : null}
      </div>
      <button className="next-btn" onClick={nextPage}>
        다음
      </button>
      <div id="recaptcha"></div>
    </div>
  );
}

export default Forgotpassword2;

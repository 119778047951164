import { useRef, useState, useEffect } from "react";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { app } from "../firebase";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Write() {
  const goHome = () => {
    navigate("/"); // 이전 페이지로 이동
  };
  const fileRef = useRef(null);
  const modalRef = useRef(null); // 모달의 DOM 참조
  const categoryButtonRef = useRef(null); // 카테고리 버튼의 DOM 참조
  const navigate = useNavigate();
  const [closeModal, setCloseModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [fieldErrorMessage, setFieldErrorMessage] = useState("");
  const [listingOkModal, setListingOkModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null); // 선택된 카테고리 상태
  const { currentUser } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    imageurl: "",
    brandname: "",
    description: "",
    content: "",
    price: "",
    personname: "",
    age: "",
    birthplace: "",
    achievements: "",
  });

  const handleClickClose = () => {
    setCloseModal(true);
  };

  const toggleModal = () => {
    setOpenModal((prevState) => !prevState);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === "price") {
      const numericValue = value.replace(/,/g, "");
      if (numericValue === "" || isNaN(numericValue)) {
        // 가격이 빈 문자열이거나 숫자가 아닌 경우
        setFormData({
          ...formData,
          [id]: "",
        });
      } else {
        // 숫자가 입력된 경우
        const formattedValue = Number(numericValue).toLocaleString();
        setFormData({
          ...formData,
          [id]: formattedValue,
        });
      }
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await fetch("https://us-central1-stories-e89af.cloudfunctions.net/start/api/listing/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...formData,
        price: Number(formData.price.replace(/,/g, "")), // 쉼표 제거 및 숫자로 변환
        category: selectedCategory,
      }),
    });
    const data = await res.json();
    if (data.success === false) {
      navigate();
      setFieldErrorMessage(data.message);
      setTimeout(() => {
        setFieldErrorMessage("");
      }, 2000);
    } else {
      setListingOkModal(true);
      setTimeout(() => {
        navigate("/");
        setListingOkModal(true);
      }, 3000);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const storage = getStorage(app);
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // 업로드 진행 상태 처리
      },
      (error) => {
        console.error("업로드 실패", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            setFormData({
              ...formData,
              imageurl: downloadURL,
            });
          })
          .catch((error) => {
            console.error("다운로드 이미지 URL 가져오기 오류 발생", error);
          });
      }
    );
  };

  const handleImageDelete = () => {
    const storage = getStorage(app);
    const imageRef = ref(storage, formData.imageurl);

    setFormData({
      ...formData,
      imageurl: "",
    });
    if (fileRef.current) {
      fileRef.current.value = null;
    } // 역할 파일을 비우고, 다시 동일한 파일을 다시 선택할 수 있도록 함
  };

  const handleCategorySelect = (item) => {
    setSelectedCategory(item);
    setOpenModal(false);
  };

  // 클릭 외부 감지 효과
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        categoryButtonRef.current &&
        !categoryButtonRef.current.contains(event.target)
        // 카테고리,모달을 제외한 부분을 클릭시
      ) {
        setOpenModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside); // 화면의 아무 곳이나 클릭했을 때 handleClickOutside 함수가 실행되도록 설정합니다.
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      {listingOkModal ? (
        <div className="listing-ok-modal">상품이 등록이 완료되었습니다.</div>
      ) : null}
      {fieldErrorMessage ? (
        <div className="field-error-message-modal">{fieldErrorMessage}</div>
      ) : null}

      {closeModal ? (
        <div
          className="write-close-modal-background"
          onClick={() => {
            setCloseModal(false);
          }}
        ></div>
      ) : null}
      <div>
        <div className="write-nav-container">
          <img
            src="closeicon2.svg"
            className="closeicon2"
            onClick={handleClickClose}
          />
          {closeModal ? (
            <div className="write-close-modal">
              <img
                onClick={() => {
                  setCloseModal(false);
                }}
                src="closeicon2.svg"
                className="write-closeicon"
              />
              <div className="write-close-modal-title">
                상품 등록을 중단 하시겠습니까?
                <div className="write-close-modal-body">
                  상품 등록을 중단하면 입력된 모든 내용이 삭제됩니다.<br></br>
                  저장을 원하시면 임시저장 기능을 활용하세요.
                </div>
                <div className="write-modal-yes-btn" onClick={goHome}>
                  네
                </div>
                <div
                  className="write-modal-no-btn"
                  onClick={() => {
                    setCloseModal(false);
                  }}
                >
                  아니오
                </div>
              </div>
            </div>
          ) : null}
          <div className="nav-in-container">
            <button className="nav-text" type="submit">
              완료
            </button>
          </div>
        </div>

        {openModal && (
          <div className="write-category-modal" ref={modalRef}>
            {["사업", "투자", "부동산"].map((item) => (
              <div
                key={item}
                onClick={() => handleCategorySelect(item)}
                className={`category-item ${
                  selectedCategory === item ? "selected" : null
                }`}
              >
                {item}
              </div>
            ))}
          </div>
        )}

        <div className="write-grid-container">
          <div className="write-a">
            <div className="write-a1">
              <div className="write-a-text">상품 이미지</div>
              <div className="write-a1-1">
                <div
                  onClick={toggleModal}
                  ref={categoryButtonRef} // 카테고리 버튼의 DOM 참조 설정
                >
                  {selectedCategory || "카테고리"}
                </div>
                <img className="write-dropdown" src="dropdown.svg" />
              </div>
            </div>
            <input
              type="file"
              ref={fileRef}
              hidden
              accept="image/*"
              id="image"
              onChange={handleImageUpload}
            />
            {formData.imageurl ? (
              <div>
                <img
                  src={formData.imageurl}
                  className="product-thumbnail-img"
                />
                <img
                  src="deleteicon3.svg"
                  onClick={handleImageDelete}
                  className="write-deleteicon3"
                />
              </div>
            ) : (
              <img
                src="productthumbnail.svg"
                className="write-product-thumbnail"
                onClick={() => fileRef.current.click()}
              />
            )}
          </div>

          <div className="write-b">
            <div className="write-b-text">상품정보</div>
            <div
              className={
                formData.personname !== "" &&
                formData.age !== "" &&
                formData.birthplace !== "" &&
                formData.achievements !== "" &&
                formData.brandname !== "" &&
                formData.description !== "" &&
                formData.content !== ""
                  ? "write-b1-entered"
                  : "write-b1"
              }
            >
              <input
                className={
                  formData.personname == ""
                    ? "write-b-person-name"
                    : "write-b-person-name-entered"
                }
                type="text"
                placeholder="전기 주인공 이름"
                id="personname"
                onChange={handleChange}
                value={formData.personname}
              />
              <input
                className={
                  formData.age == ""
                    ? "write-b-person-name"
                    : "write-b-person-name-entered"
                }
                type="text"
                placeholder="출생일 (년 / 월 / 일)"
                id="age"
                onChange={handleChange}
                value={formData.age}
              />
                            <input
                className={
                  formData.birthplace == ""
                    ? "write-b-person-name"
                    : "write-b-person-name-entered"
                }
                type="text"
                placeholder="출생지"
                id="birthplace"
                onChange={handleChange}
                value={formData.birthplace}
              />
                            <input
                className={
                  formData.achievements == ""
                    ? "write-b-person-name"
                    : "write-b-person-name-entered"
                }
                type="text"
                placeholder="업적"
                id="achievements"
                onChange={handleChange}
                value={formData.achievements}
              />
              <input
                className={
                  formData.brandname == ""
                    ? "write-b-product-name"
                    : "write-b-product-name-entered"
                }
                type="text"
                placeholder="브랜드명"
                id="brandname"
                onChange={handleChange}
                value={formData.brandname}
              />

              <textarea
                className={
                  formData.description == ""
                    ? "write-b-product-introduce"
                    : "write-b-product-introduce-entered"
                }
                type="text"
                placeholder="상품 소개"
                id="description"
                onChange={handleChange}
                value={formData.description}
              />
              <textarea
                className="write-b-your-story"
                type="text"
                placeholder="컨텐츠"
                id="content"
                onChange={handleChange}
                value={formData.content}
              />
            </div>
          </div>

          <div className="write-d">
            {formData.price === "" ? (
              <span className="write-price-input-false">₩</span>
            ) : null}
            {formData.price !== "" ? (
              <span className="write-price-input-true">₩</span>
            ) : null}
            <div className="write-d-text">가격</div>
            <input
              className={
                formData.price === ""
                  ? "write-d-input"
                  : "write-d-input-entered"
              }
              type="text"
              id="price"
              value={formData.price}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default Write;

import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";

import NotFound from "./pages/Notfound";
import Home from "./pages/Home";
import Search from "./pages/Search";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Forgotpassword from "./pages/Forgotpassword";
import Forgotpassword2 from "./pages/Forgotpassword2";
import Forgotpassword3 from "./pages/Forgotpassword3";
import Changepassword from "./pages/Chagepassword";
import Cart from "./pages/Cart";
import Profile from "./pages/Profile";
import Menu from "./pages/Menu";
import Menucategory from "./pages/Menucategory";
import Detail from "./pages/Detail";
import Review from "./pages/Review";
import Profileedit from "./pages/Profileedit";
import Purchasehistory from "./pages/Purchasehistory";
import Write from "./pages/Write";
import Readinglist from "./pages/Readinglist";
import Cartcheckout from "./pages/Cartcheckout";
import Detailcheckout from "./pages/Detailcheckout";
import Completecheckout from "./pages/Completecheckout";
import Reviewwrite from "./pages/Reviewwrite";
import Reading from "./pages/Reading";
import Cs from "./pages/Cs";
import Hello from "./pages/Hello";

import Unsupporteddevice from "./pages/Unsupporteddevice";

import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/Scrolltotop";

/*
명령어
npm run build - 프론트
firebase deploy - 프론트
firebase deploy --only functions - 서버
firebase functions:log - 로그 오류 확인
firebase functions:config:get - 클라우드 펑션 전용 환경 변수 확인
*/

function DeviceCheck() {
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    // /hello 경로에서는 조건을 무시
    if (location.pathname === "/hello") {
      return;
    }

    // 디바이스 크기가 450px 이상일 때만 리디렉션
    if (window.innerWidth > 450) {
      navigate("/unsupporteddevice");
    }
  }, [location.pathname]); // location.pathname만 의존성으로 추가
}


function App() {

  const clearSessionStorage = () => {
    sessionStorage.clear();
  };

  // 새로고침 시 세션 스토리지에 기록된 페이지 스크롤 위치를 모두 삭제합니다.
  // Safari 대응을 위해 load 대신 pagehide 이벤트 사용합니다.
  useEffect(() => {
    window.addEventListener('pagehide', clearSessionStorage);

    return () => window.removeEventListener('pagehide', clearSessionStorage);
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <DeviceCheck />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route element={<PrivateRoute />}>
            <Route path="/profileedit" element={<Profileedit />} />
            <Route path="/cartcheckout" element={<Cartcheckout />} />
            <Route path="/purchasehistory" element={<Purchasehistory />} />
            <Route path="/readinglist" element={<Readinglist />} />
            <Route path="/reading/:id" element={<Reading />} />
            <Route path="/write" element={<Write />} />
            <Route path="/reviewwrite/:id" element={<Reviewwrite />} />
            <Route path="/detailcheckout/:id" element={<Detailcheckout />} />
            <Route
              path="/completecheckout/:product_id"
              element={<Completecheckout />}
            />
          </Route>
          <Route path="*" element={<NotFound />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/hello" element={<Hello />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/cs" element={<Cs />} />
          <Route path="/unsupporteddevice" element={<Unsupporteddevice />} />
          <Route path="/search" element={<Search />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route path="/forgotpassword2/:id" element={<Forgotpassword2 />} />
          <Route path="/forgotpassword3/:id" element={<Forgotpassword3 />} />
          <Route path="/changepassword" element={<Changepassword />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/menu/:category" element={<Menucategory />} />
          <Route path="/detail/:listing_id" element={<Detail />} />
          <Route path="/review/:id" element={<Review />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

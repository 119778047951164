import { createSlice } from "@reduxjs/toolkit";

// 이 리덕스 슬라이스의 초기 상태
const initialState = {
  currentUser: null,
  error: null,
};

const userSlice = createSlice({
  name: "user", // 슬라이스의 작명
  initialState, // 슬라이스의 초기상태
  reducers: {
    // 슬라이스 상태 변경함수
    signInSuccess: (state, action) => {
      state.currentUser = action.payload;
      state.error = null;
    },
    signInFailure: (state, action) => {
      state.error = action.payload;
    },
    updateUserSuccess: (state, action) => {
      state.currentUser = action.payload;
      state.error = null;
    },
    signOutUserSuccess: (state, action) => {
      state.currentUser = null;
      state.error = null;
    },
  },
});

export const {
  signInStart,
  signInFailure,
  signInSuccess,
  updateUserSuccess,
  updateUserFailure,
  signOutUserFailure,
  signOutUserSuccess,
} = userSlice.actions;

export default userSlice.reducer;

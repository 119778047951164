import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Readinglist() {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      try {
        const res = await fetch(
          `https://us-central1-stories-e89af.cloudfunctions.net/start/api/checkout/get/${currentUser._id}`,
          {
            credentials: "include",
          }
        );
        const data = await res.json();

        const productIds = data.products.map((product) => product.product_id);
        const purchaseDates = data.products.reduce((acc, product) => {
          acc[product.product_id] = product.purchase_date;
          return acc;
        }, {});

        const fetchProductDetails = productIds.map((id) =>
          fetch(
            `https://us-central1-stories-e89af.cloudfunctions.net/start/api/listing/checkout/get/${id}`
          ).then((res) => (res.ok ? res.json() : null))
        );

        const productsData = await Promise.all(fetchProductDetails);
        const mergedProducts = productsData
          .filter((product) => product !== null)
          .map((product) => ({
            ...product,
            purchase_date: purchaseDates[product._id],
          }));

        setProducts(mergedProducts);
        setIsLoading(false);
      } catch (error) {
        console.error("결제 내역을 찾을수 없습니다.", error);
        setIsLoading(false)
      }
    };

    fetchPurchaseHistory();
  }, [currentUser._id]);

  const goBack = () => {
    navigate("/profile"); // 이전 페이지로 이동
  };

  console.log(products);

  /*
  const navigateProduct = (productId) => {
    navigate(`/reading/${productId}`); // 제품 ID를 URL 경로로 전달
  }; */


  return (
    <div>
      <div className="readinglist-nav-container">
        <img onClick={goBack} src="back.svg" className="backicon" />
        <span>읽기 목록</span>
      </div>
      <div className="readinglist-container">
        {isLoading
          ? Array.from({ length: 15 }).map((_, index) =>
          <div class="skeleton skeleton-readinglist-item">
            <div className="skeleton skeleton-readinglist-thumbnail"></div>
          </div>
          
        )
          : products.map((product) => (
              <div
                key={product._id}
                // onClick={() => navigateProduct(product._id)}
                onClick={() => {alert("회원 승인 대기 사용자는 서비스 이용이 제한됩니다.")}}
                className="readinglist-item"
              >
                <img src={product.imageurl} className="readinglist-thumbnail" />
              </div>
            ))}
      </div>
    </div>
  );
}

export default Readinglist;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

function Reading() {
  const params = useParams();
  const [readingItem, setReadingItem] = useState("");
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageNavigationVisible, setPageNavigationVisible] = useState(false); // 페이지 네비게이션 표시 여부 상태 추가
  const contentRef = useRef(null);
  const sliderRef = useRef(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const [fontSize, setFontSize] = useState(16); // 기본 폰트 사이즈
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate(); // useNavigate 훅 사용

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (pageNavigationVisible) {
      updateSlider(currentPage); // 네비게이션이 열리면 슬라이더 상태 업데이트
    }
  }, [pageNavigationVisible, currentPage]);

  useEffect(() => {
    const fetchGetReadingItem = async () => {
      const res = await fetch(
        `https://us-central1-stories-e89af.cloudfunctions.net/start/api/listing/reading/get/${params.id}`,
        {
          credentials: "include",
        }
      );
      const data = await res.json();
      setReadingItem(data.content);
      setIsLoading(false);
    };
    fetchGetReadingItem();
  }, [params.id]);


// 컨텐츠 페이지 분할
const splitContentIntoPages = () => {
  const container = contentRef.current;
  if (!container) return;

  const containerHeight = container.clientHeight;
  const tempPages = [];
  let currentPageText = "";
  let charLimit = 0;

  // 높이에 따라 글자 수 기준 설정
  if (containerHeight >= 850) {
    charLimit = 700;
  } else if (containerHeight >= 800) {
    charLimit = 650;
  } else if (containerHeight >= 750) {
    charLimit = 600;
  } else if (containerHeight >= 700) {
    charLimit = 550;
  } else if (containerHeight >= 650) {
    charLimit = 500;
  } else {
    charLimit = 400; // 기본값
  }

  let wordArray = readingItem.split(" ");
  let currentPageCharCount = 0;

  // 페이지 분할 로직
  for (let word of wordArray) {
    // 현재 페이지 글자 수에 추가
    currentPageCharCount += word.length + 1; // 공백을 포함하여 글자 수 계산
    currentPageText += word + " ";

    if (currentPageCharCount >= charLimit) {
      // 페이지 나누기
      tempPages.push(currentPageText.trim());
      currentPageText = ""; // 다음 페이지를 위한 초기화
      currentPageCharCount = 0; // 글자 수 초기화
    }
  }

  if (currentPageText.trim()) {
    tempPages.push(currentPageText.trim());
  }

  setPages(tempPages);
};


  useEffect(() => {
    if (readingItem) {
      splitContentIntoPages();
    }
  }, [readingItem]);

  const prevPage = () => {
    const newPage = Math.max(currentPage - 1, 0);
    setCurrentPage(newPage);
    updateSlider(newPage);
  };

  const nextPage = () => {
    const newPage = Math.min(currentPage + 1, pages.length - 1);
    setCurrentPage(newPage);
    updateSlider(newPage);
  };

  const updateSlider = (newPage) => {
    if (sliderRef.current) {
      const max = sliderRef.current.max;
      const progress = (newPage / max) * 100;
      sliderRef.current.style.setProperty("--slider-progress", `${progress}%`);
    }
  };

  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setCurrentPage(value);
    updateSlider(value);
  };

  const getProgressPercentage = () => {
    return (currentPage / (pages.length - 1)) * 100;
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX; // 터치 시작 X 좌표 저장
    touchEndX.current = touchStartX.current; // 초기화
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX; // 터치 이동 중의 X 좌표 저장
  };

  const handleTouchEnd = () => {
    const MIN_SWIPE_DISTANCE = 50; // 슬라이드를 인식할 최소 거리

    // 오른쪽에서 왼쪽으로 스와이프
    if (touchStartX.current - touchEndX.current > MIN_SWIPE_DISTANCE) {
      nextPage();
    }

    // 왼쪽에서 오른쪽으로 스와이프
    if (touchEndX.current - touchStartX.current > MIN_SWIPE_DISTANCE) {
      prevPage();
    }

    // 슬라이드 후 값을 초기화하여 다음 터치에 영향을 주지 않도록 함
    touchStartX.current = 0;
    touchEndX.current = 0;
  };

  const togglePageNavigation = () => {
    setPageNavigationVisible((prevVisible) => !prevVisible); // 클릭 시 상태 변경
  };

  const adjustFontSize = () => {
    setFontSize((prevSize) => {
      const newSize = prevSize === 16 ? 18 : 16; // 폰트 크기를 16px과 18px로 토글
      return newSize;
    });
  };

  useEffect(() => {
    splitContentIntoPages(); // 폰트 크기 변경 후 페이지 재계산
  }, [fontSize]); // fontSize가 변경될 때마다 페이지 수 재계산

  useEffect(() => {
    updateSlider(currentPage); // 페이지가 변경될 때마다 슬라이더 업데이트
  }, [pages]); // pages가 변경될 때마다 슬라이더 업데이트

  
  return (
    <div className="reading-container">
      <div className="reading-nav-container">
        <div className="reading-nav-left">
          <span
            onClick={() => navigate("/readinglist")}
            className="reading-close"
          >
            닫기
          </span>
          <div className="reading-vertical-bar"></div>
          <img
            src="/fontsizeup.svg"
            onClick={adjustFontSize}
            className="reading-fontsizeup"
          />
        </div>
        <div>
          <img
            onClick={prevPage}
            src="/prevbtn.svg"
            className="prev-page-btn"
          />
          <img
            className="next-page-btn"
            src="/nextbtn.svg"
            onClick={nextPage}
          />
        </div>
      </div>
      <div className="reading-content-container">
        {isLoading ? (
          <div className="skeleton skeleton-reading-content">
          </div>
        ) : (
          <div
            ref={contentRef}
            style={{ fontSize: `${fontSize}px` }} // 동적으로 폰트 사이즈 적용
            className="reading-content"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onClick={togglePageNavigation}
          >
            {pages.length > 0 ? pages[currentPage] : null}
          </div>
        )}

        {pageNavigationVisible && ( // pageNavigationVisible가 true일 때만 네비게이션 표시
          <div className="page-navigation">
            <div className="progress-percent">
              {Math.round(getProgressPercentage())}%
            </div>
            <input
              ref={sliderRef}
              type="range"
              min="0"
              max={pages.length - 1}
              value={currentPage}
              onChange={handleSliderChange}
              className="page-slider"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Reading;

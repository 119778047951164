import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function Forgotpassword() {
  const navigate = useNavigate(); // useNavigate 훅 사용
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");


  const goBack = () => {
    navigate("/signin"); // 이전 페이지로 이동
  };

  const handleChange = (e) => {
    setFormData({ ...formData, id: e.target.value }); // 입력값을 formData에 저장
    if (error) {
      setError(""); // 입력값이 변경될 때 error를 초기화
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // 폼 기본 제출 방지

    try {
      const res = await fetch(
        "https://us-central1-stories-e89af.cloudfunctions.net/start/api/auth/changepassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: formData.id }), // ID를 API로 전송
        }
      );

      const data = await res.json();
      if (res.ok) {
        navigate(`/forgotpassword2/${formData.id}`); // 성공 시 페이지 이동
      } else {
        setError(data);
      }
    } catch (error) {
      console.error("Error:", error);
      // 에러 발생 시 처리 로직 추가
    }
  };

  return (
    <div>
      <div className="forgotpassword-nav-container">
        <img onClick={goBack} src="back.svg" className="backicon" />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="forgot-box">
          <div className="forgot-text">비밀번호 찾기</div>
          <input
            type="text"
            placeholder="아이디"
            className={error !== "" ? "forgot-box-error-id" : "forgot-box-id"}
            onChange={handleChange}
          />
          {error !== "" ? (
            <span className="forgotpassword-error-message">{error}</span>
          ) : null}
        </div>
        <button type="submit" className="next-btn">
          다음
        </button>
      </form>
    </div>
  );
}

export default Forgotpassword;

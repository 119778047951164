import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

function Menucategory() {
  const params = useParams();
  const [categoryListingData, setCategoryListingData] = useState([]);
  const navigate = useNavigate(); // useNavigate 훅 사용
  const { currentUser } = useSelector((state) => state.user);
  const [addToCartModal, setAddToCartModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const goBack = () => {
    navigate("/menu"); // 이전 페이지로 이동
  };

  useEffect(() => {
    if (params.category) {
      loadListingCategoryData();
    }
  }, [params.category]);

  const loadListingCategoryData = async () => {
    const res = await fetch(
      `https://us-central1-stories-e89af.cloudfunctions.net/start/api/listing/category/get/${params.category}`,
      {
        credentials: "include",
      }
    );
    const data = await res.json();
  
    if (res.ok) {
      // reviewCount 많은 순으로 정렬
      data.sort((a, b) => b.reviewCount - a.reviewCount);
      setCategoryListingData(data);
      setIsLoading(false);
    }
  };
  
  

  const handleAddToCartClick = async (e, listing) => {
    e.preventDefault(); // 클릭 이벤트 전파 방지

    if (!currentUser) {
      navigate("/signin");
      return; // 로그인 페이지로 이동 후 함수 종료
    }

    setAddToCartModal(true);

    const cartFormData = {
      user_id: currentUser._id, // 현재 사용자 ID
      products: [
        {
          product_id: listing._id,
        },
      ],
    };

    try {
      const res = await fetch(
        "https://us-central1-stories-e89af.cloudfunctions.net/start/api/cart/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(cartFormData),
          credentials: "include",
        }
      );

      const data = await res.json();

      if (res.ok) {
        setTimeout(() => {
          setAddToCartModal(false);
        }, 2000);
      } else {
        alert("장바구니 추가에 실패했습니다.");
      }
    } catch (error) {
      alert("오류가 발생했습니다.");
    }
  };

  return (
    <div>
      {addToCartModal ? (
        <div className="add-to-cart-modal">장바구니에 추가되었습니다.</div>
      ) : null}
      <div className="menucategory-nav-container">
        <div className="menucategory-a1">
          <img onClick={goBack} src="/back.svg" className="backicon" />
        </div>
        <div className="menucategory-a2">
          <Link to="/search" className="link-reset">
            <img src="/search.svg" className="menucategory-searchicon" />
          </Link>
          <Link to="/" className="link-reset">
            <img src="/home.svg" className="menucategory-homeicon" />
          </Link>
        </div>
      </div>

      <div className="menucategory-ProductList">
        {isLoading
          ? Array.from({ length: 10 }).map((_, index) => (
              <div className="home-product">
                <div className="b1">
                  <div className="skeleton skeleton-product-thumbnail"> </div>
                </div>
                <div className="b2">
                  <div className="product-container">
                    <div className="skeleton skeleton-product-name"> </div>
                    <div className="skeleton skeleton-who-wrote-this"> </div>
                    <div className="skeleton skeleton-detail-review-rating-star"> </div>
                    <div className="skeleton skeleton-product-price"> </div>
                    <div className="skeleton skeleton-add-to-cart"> </div>
                  </div>
                </div>
              </div>
            ))
          : categoryListingData.map((listing, index) => (
              <Link to={`/detail/${listing._id}`}>
                <div key={index} className="menucategory-result-container">
                  <div className="menucategory-b1">
                    <img src={listing.imageurl} className="product-thumbnail" />
                  </div>
                  <div className="menucategory-b2">
                    <div className="product-container">
                      <div className="product-name">{listing.personname}</div>
                      <div className="who-wrote-this">
                        <div>{listing.brandname}</div>
                      </div>
                      <div className="detail-review-rating-star">
                        <div>
                          <img
                            src={
                              listing.rating >= 1
                                ? "/bigstarfilled.svg"
                                : listing.rating >= 0.5
                                ? "/halfstar2.svg"
                                : "/bigstarempty2.svg"
                            }
                          />
                          <img
                            src={
                              listing.rating >= 2
                                ? "/bigstarfilled.svg"
                                : listing.rating >= 1.5
                                ? "/halfstar2.svg"
                                : "/bigstarempty2.svg"
                            }
                          />
                          <img
                            src={
                              listing.rating >= 3
                                ? "/bigstarfilled.svg"
                                : listing.rating >= 2.5
                                ? "/halfstar2.svg"
                                : "/bigstarempty2.svg"
                            }
                          />
                          <img
                            src={
                              listing.rating >= 4
                                ? "/bigstarfilled.svg"
                                : listing.rating >= 3.5
                                ? "/halfstar2.svg"
                                : "/bigstarempty2.svg"
                            }
                          />
                          <img
                            src={
                              listing.rating >= 4.8
                                ? "/bigstarfilled.svg"
                                : listing.rating >= 4.5
                                ? "/halfstar2.svg"
                                : "/bigstarempty2.svg"
                            }
                          />
                        </div>
                        <div className="rating">{listing.reviewCount}</div>
                      </div>
                      <div className="product-price">{listing.price}원</div>
                      <button
                        className="add-to-cart"
                        onClick={(e) => handleAddToCartClick(e, listing)}
                      >
                        장바구니 담기
                      </button>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
      </div>
    </div>
  );
}

export default Menucategory;

import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { signOutUserSuccess } from "../redux/user/userSlice";

function Profile() {
  const navigate = useNavigate(); // useNavigate 훅 사용
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);


  const goBack = () => {
    navigate("/"); // 이전 페이지로 이동
  };

  const handleSignOut = async () => {
    const res = await fetch("https://us-central1-stories-e89af.cloudfunctions.net/start/api/auth/signout", {
      credentials: "include",
    });

    const data = await res.json();
    navigate("/");
    dispatch(signOutUserSuccess());
  };

  if (!currentUser) {
    return null; // currentUser가 없으면 컴포넌트를 렌더링하지 않음
  }

  return (
    <div>
      <div className="profile-nav-container">
        <img onClick={goBack} src="back.svg" className="backicon" />
      </div>

      <div className="profile-container1" onClick={() => navigate("/profileedit")}>
        <div className="profile-container1-1">
          {currentUser ? (
            <img
              src={currentUser.profileimg}
              className="profileicon-profilepage"
            />
          ) : (
            <img className="profile-noprofileicon" src="noprofile.svg" />
          )}
        </div>
        <div className="profile-container1-2">
          <div className="profile-name">{currentUser.nickname}</div>
          <div className="edit-text">프로필 및 개인정보 수정</div>
        </div>
        <div className="profile-container1-3">
          <img className="profile-next-icon" src="profilenexticon.svg" />
        </div>
      </div>

      <div className="profile-container2">
        <div className="profile-container2-1" onClick={() => navigate("/purchasehistory")}>
          <img className="profile-purchasehistory" src="purchasehistory.svg" />
          <div>구매내역</div>
          <div className="profile-next-icon-container">
            <img className="profile-next-icon-item" src="profilenexticon.svg" />
          </div>
        </div>

        <Link to="/readinglist">
          <div className="profile-container2-2">
            <img className="profile-read" src="readlist.svg" />
            <div onClick={() => navigate("/readinglist")}>읽기 목록</div>
            <div className="profile-next-icon-container">
              <img
                className="profile-next-icon-item"
                src="profilenexticon.svg"
              />
            </div>
          </div>
        </Link>
        <Link to="/cs">
        <div className="profile-container2-3">
          <img className="profile-cs" src="cs.svg" />
          <div>문의</div>
          <div className="profile-next-icon-container">
            <img className="profile-next-icon-item" src="profilenexticon.svg" />
          </div>
        </div>
        </Link>
      </div>
      <button onClick={handleSignOut} className="bottom-btn">
        로그아웃
      </button>
    </div>
  );
}

export default Profile;

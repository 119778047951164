import React from 'react'

function Unsupporteddevice() {
  return (
    <div>
    <h1 style={{color : "#222222", fontSize: "24px", marginLeft : "10px"}}>지원되지 않는 디바이스입니다.</h1>
    <a style={{color : "#222222", marginLeft : "10px"}}>베타 서비스는 모바일에서만 이용 가능합니다.</a>
  </div>
  )
}

export default Unsupporteddevice
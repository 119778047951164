import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";

function Detailcheckout() {
  const goBack = () => navigate(-1);
  const navigate = useNavigate();
  const [listingDetail, setListingDetail] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("stories");
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();

  useEffect(() => {
    const fetchListing = async () => {
      const res = await fetch(
        `https://us-central1-stories-e89af.cloudfunctions.net/start/api/listing/detail/get/${params.id}`,
        {
          credentials: "include",
        }
      );
      const data = await res.json();
      if (data.success === false) {
        return;
      } else {
        setListingDetail(data);
        setIsLoading(false);
      }
    };
    fetchListing();
  }, [params.id]);

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const handleCheckout = () => {
    navigate(`/completecheckout/${params.id}`);
  };

  return (
    <div>
      <div className="cart-nav-container">
        <img onClick={goBack} src="/back.svg" className="backicon" />
        <div className="cart-text">결제</div>
      </div>

      <div className="cart-checkout-product-container">
        <div className="cart-checkout-product-text">주문 상품</div>
        {isLoading ? (
          Array.from({ length: 1 }).map((_, index) => (
            <div className="cart-checkout-product">
              <div className="cart-a1">
                <div className="skeleton skeleton-cart-product-thumbnail"></div>
              </div>
              <div className="cart-a2">
                <div className="skeleton skeleton-cart-product-name"></div>
                <div className="skeleton skeleton-cart-who-wrote-this"></div>
                <div className="skeleton skeleton-cart-product-price"></div>
              </div>
              <div className="cart-a3"></div>
            </div>
          ))
        ) : (
          <div className="cart-checkout-product">
            <div className="cart-a1">
              <div className="cart-product-thumbnail">
                <img
                  src={listingDetail ? listingDetail.imageurl : null}
                  className="cart-product-thumbnail"
                />
              </div>
            </div>

            <div className="cart-a2">
              <div className="cart-product-name">
                {listingDetail ? listingDetail.personname : null}
              </div>
              <div className="cart-who-wrote-this">
                {listingDetail ? listingDetail.brandname : null}
              </div>
              <div className="cart-product-price">
                {listingDetail ? listingDetail.price : null}원 
              </div>
            </div>
          </div>
        )}

        <div className="cart-checkout-border-bottom"></div>

        <div className="checkout-container">
          <div className="total-container">
            <div className="total">합계</div>
            <div className="total-price">
              {listingDetail ? listingDetail.price : null} 원
            </div>
          </div>
          <button onClick={handleCheckout} className="continue-checkout">
            결제
          </button>
        </div>
      </div>
      <div className="payment-method-container">
        <div className="payment-method-text">결제 수단</div>
        <label className="payment-select">
          Stories Beta · 무료
          <input
            type="radio"
            name="payment-method"
            value="stories"
            checked={selectedPaymentMethod === "stories"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          신용 · 체크카드
          <input
            type="radio"
            name="payment-method"
            value="credit"
            checked={selectedPaymentMethod === "credit"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          네이버 페이
          <input
            type="radio"
            name="payment-method"
            value="naver"
            checked={selectedPaymentMethod === "naver"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          카카오 페이
          <input
            type="radio"
            name="payment-method"
            value="kakao"
            checked={selectedPaymentMethod === "kakao"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          토스 페이
          <input
            type="radio"
            name="payment-method"
            value="toss"
            checked={selectedPaymentMethod === "toss"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          휴대폰
          <input
            type="radio"
            name="payment-method"
            value="phone"
            checked={selectedPaymentMethod === "phone"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          무통장 입금
          <input
            type="radio"
            name="payment-method"
            value="bank"
            checked={selectedPaymentMethod === "bank"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          실시간 계좌이체
          <input
            type="radio"
            name="payment-method"
            value="real-time"
            checked={selectedPaymentMethod === "real-time"}
            onChange={handlePaymentMethodChange}
          />
        </label>
      </div>
    </div>
  );
}

export default Detailcheckout;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


function CartCheckout() {
  const navigate = useNavigate();
  const goBack = () => navigate("/cart");
  const { currentUser } = useSelector((state) => state.user);
  const [productDetails, setProductDetails] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("stories");
  const [isLoading, setIsLoading] = useState(true);

  const fetchCartData = async () => {
    try {
      const res = await fetch(
        `https://us-central1-stories-e89af.cloudfunctions.net/start/api/cart/get/${currentUser._id}`
      );
      const data = await res.json();

      const productIds = data.products.map((product) => product.product_id);
      const fetchProductDetails = productIds.map((id) =>
        fetch(
          `https://us-central1-stories-e89af.cloudfunctions.net/start/api/listing/cart/get/${id}`
        ).then((res) => {
          if (!res.ok) {
            return null; // 응답이 성공적이지 않으면 null 반환
          }
          return res.json();
        })
      );

      const details = await Promise.all(fetchProductDetails);
      // null이 아닌 상품만 필터링
      setProductDetails(details.filter((detail) => detail !== null));
      setIsLoading(false);
    } catch (error) {
      console.error("데이터를 불러오기 실패", error);
    }
  };

  useEffect(() => {
    fetchCartData();
  }, [currentUser._id]);

  const totalPrice = productDetails.reduce((sum, product) => {
    // 상품이 유효하고 가격이 정의되어 있는지 확인
    const priceString = product?.price || "0";
    // 가격을 숫자로 변환
    const price = parseFloat(priceString.replace(/[^0-9.-]+/g, "")) || 0;
    return sum + price;
  }, 0);

  // 숫자를 지역화된 문자열로 변환
  const formattedTotalPrice = totalPrice.toLocaleString("ko-KR", {
    currency: "KRW",
  });

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  //장바구니 상품데이터들 _id만 추출
  const product_id = productDetails.map((product) => product._id);

  
  const handleCheckout = () => {
    navigate(`/completecheckout/${product_id}`);
  };

  return (
    <div className="CartGrid">
      <div className="cart-nav-container">
        <img onClick={goBack} src="back.svg" className="backicon" />
        <div className="cart-text">결제</div>
      </div>

      <div className="cart-checkout-product-container">
        <div className="cart-checkout-product-text">주문 상품</div>
        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => (
              <div className="cart-checkout-product">
                <div className="cart-a1">
                  <div className="skeleton skeleton-cart-product-thumbnail"></div>
                </div>
                <div className="cart-a2">
                  <div className="skeleton skeleton-cart-product-name"></div>
                  <div className="skeleton skeleton-cart-who-wrote-this"></div>
                  <div className="skeleton skeleton-cart-product-price"></div>
                </div>
                <div className="cart-a3"></div>
              </div>
            ))
          : productDetails.map((product, index) => (
              <div className="cart-checkout-product" key={index}>
                <div className="cart-a1">
                  <div className="cart-product-thumbnail">
                    <img
                      src={product.imageurl}
                      className="cart-product-thumbnail"
                    />
                  </div>
                </div>
                <div className="cart-a2">
                  <div className="cart-product-name">{product.personname}</div>
                  <div className="cart-who-wrote-this">
                    {product.brandname}
                  </div>
                  <div className="cart-product-price">{product.price}원</div>
                </div>
              </div>
            ))}

        <div className="cart-checkout-border-bottom"></div>
        <div className="checkout-container">
          <div className="total-container">
            <div className="total">합계</div>
            <div className="total-price">{formattedTotalPrice}원</div>
          </div>

          <button onClick={handleCheckout} className="continue-checkout">
            결제
          </button>
        </div>
      </div>
      <div className="payment-method-container">
        <div className="payment-method-text">결제 수단</div>
        <label className="payment-select">
          Stories Beta · 무료
          <input
            type="radio"
            name="payment-method"
            value="stories"
            checked={selectedPaymentMethod === "stories"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          신용 · 체크카드
          <input
            type="radio"
            name="payment-method"
            value="credit"
            checked={selectedPaymentMethod === "credit"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          네이버 페이
          <input
            type="radio"
            name="payment-method"
            value="naver"
            checked={selectedPaymentMethod === "naver"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          카카오 페이
          <input
            type="radio"
            name="payment-method"
            value="kakao"
            checked={selectedPaymentMethod === "kakao"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          토스 페이
          <input
            type="radio"
            name="payment-method"
            value="toss"
            checked={selectedPaymentMethod === "toss"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          휴대폰
          <input
            type="radio"
            name="payment-method"
            value="phone"
            checked={selectedPaymentMethod === "phone"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          무통장 입금
          <input
            type="radio"
            name="payment-method"
            value="bank"
            checked={selectedPaymentMethod === "bank"}
            onChange={handlePaymentMethodChange}
          />
        </label>
        <label className="payment-select">
          실시간 계좌이체
          <input
            type="radio"
            name="payment-method"
            value="real-time"
            checked={selectedPaymentMethod === "real-time"}
            onChange={handlePaymentMethodChange}
          />
        </label>
      </div>
    </div>
  );
}

export default CartCheckout;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function Forgotpassword3() {
  const navigate = useNavigate(); // useNavigate 훅 사용
  const params = useParams();
  const [formData, setFormData] = useState({});
  const [error, setError] = useState("");
  const [successModal, setSuccessModal] = useState(false);

  const goBack = () => {
    navigate(-1); // 이전 페이지로 이동
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
    if (error) {
      setError(""); // 입력값이 변경될 때 error를 초기화
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // 폼 기본 제출 방지

    if (!formData.newpassword || !formData.newpasswordconfirm) {
      return;
    }
    if (formData.newpassword !== formData.newpasswordconfirm) {
      setError(true);
      return;
    }

    // 위 조건 문제없을시, 아래코드 실행
    try {
      const res = await fetch(
        `https://us-central1-stories-e89af.cloudfunctions.net/start/api/auth/changepassword3/${params.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData), // ID를 API로 전송
        }
      );

      const data = await res.json();
      // 3초후 이동, 비밀번호 변경후 모달창 뜨도록
      if (res.ok) {
        setSuccessModal(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  console.log(formData);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {successModal ? (
          <div className="forgotpassword3-success-modal">
            비밀번호가 변경되었습니다.
          </div>
        ) : null}
        <div className="forgotpassword-nav-container">
          <img onClick={goBack} src="/back.svg" className="backicon" />
        </div>
        <div className="forgot-box">
          <div className="forgot-text">비밀번호 변경</div>
          <input
            type="password"
            id="newpassword"
            placeholder="새 비밀번호"
            className={error !== "" ? "newpassword-error" : "newpassword"}
            onChange={handleChange}
          />
          <input
            type="password"
            id="newpasswordconfirm"
            placeholder="새 비밀번호 확인"
            className={
              error !== "" ? "newpasswordconfirm-error" : "newpasswordconfirm"
            }
            onChange={handleChange}
          />
          {error !== "" ? (
            <span className="forgotpassword3-error-message">
              비밀번호가 일치하지 않습니다.
            </span>
          ) : null}
        </div>
        <button type="submit" onClick={handleSubmit} className="next-btn">
          완료
        </button>
      </form>
    </div>
  );
}

export default Forgotpassword3;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Purchasehistory() {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  const [products, setProducts] = useState([]);
  const [reviewStatus, setReviewStatus] = useState({}); // 리뷰 작성 상태를 저장할 상태
  const [isLoading, setIsLoading] = useState(true);

  const goBack = () => {
    navigate("/profile");
  };

  useEffect(() => {
    const fetchPurchaseHistory = async () => {
      try {
        const res = await fetch(
          `https://us-central1-stories-e89af.cloudfunctions.net/start/api/checkout/get/${currentUser._id}`,
          {
            credentials: "include",
          }
        );
        const data = await res.json();

        const productIds = data.products.map((product) => product.product_id);
        const purchaseDates = data.products.reduce((acc, product) => {
          acc[product.product_id] = product.purchase_date;
          return acc;
        }, {});

        const fetchProductDetails = productIds.map((id) =>
          fetch(
            `https://us-central1-stories-e89af.cloudfunctions.net/start/api/listing/checkout/get/${id}`
          ).then((res) => (res.ok ? res.json() : null))
        );

        const productsData = await Promise.all(fetchProductDetails);
        const mergedProducts = productsData
          .filter((product) => product !== null)
          .map((product) => ({
            ...product,
            purchase_date: purchaseDates[product._id],
          }));

        setProducts(mergedProducts);
        setIsLoading(false);
      } catch (error) {
        console.error("결제 내역을 찾을수 없습니다.", error);
        setIsLoading(false)
      }
    };

    fetchPurchaseHistory();
  }, [currentUser._id]);

  // 각 상품에 대해 리뷰 작성 상태를 확인
  useEffect(() => {
    const checkReviewStatus = async () => {
      try {
        const status = {};
        for (const product of products) {
          const res = await fetch(
            `https://us-central1-stories-e89af.cloudfunctions.net/start/api/review/get/status/${currentUser._id}/${product._id}`
          );
          const data = await res.json();
          status[product._id] = data.reviewed;
        }
        setReviewStatus(status);
      } catch (error) {
        console.error("Failed to check review status:", error);
      }
    };

    if (products.length > 0) {
      checkReviewStatus();
    }
  }, [products, currentUser._id]);

  const handleReviewWrite = (productId) => {
    navigate(`/reviewwrite/${productId}`);
  };

  
  return (
    <div>
      <div className="purchasehistory-nav-container">
        <img onClick={goBack} src="back.svg" className="backicon" />
        <div className="purchasehistory-text">구매 내역</div>
      </div>

      <div className="purchasehistory-product-container">
        {isLoading
          ? Array.from({ length: 10 }).map((_, index) => (
              <div className="purchasehistory-product">
                <div className="purchasehistory-a1">
                  <div className="skeleton skeleton-purchasehistory-product-thumbnail"></div>
                </div>
                <div className="purchasehistory-a2">
                  <div className="skeleton skeleton-purchase-date"></div>
                  <div className="skeleton skeleton-purchasehistory-who-wrote-this"></div>
                  <div className="skeleton skeleton-purchasehistory-product-price"></div>
                </div>
                <div className="purchasehistory-a4">
                  <div class="skeleton skeleton-purchasehistory-review-btn"></div>
                  <div class="skeleton skeleton-purchasehistory-cancleorder-btn"></div>
                </div>
              </div>
            ))
          : products.map((product) => (
              <div key={product._id} className="purchasehistory-product">
                <div className="purchasehistory-a1">
                  <img
                    src={product.imageurl}
                    className="purchasehistory-product-thumbnail"
                  />
                </div>

                <div className="purchasehistory-a2">
                  <div className="purchase-date">
                    {`${new Date(product.purchase_date)
                      .toLocaleDateString("ko-KR", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                      .replace(/ /g, "")} ${new Date(
                      product.purchase_date
                    ).toLocaleTimeString("ko-KR", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })}`}
                    <span className="purchasehistory-purchase-text">구매</span>
                  </div>
                  <div className="purchasehistory-who-wrote-this">
                    {product.personname || "Unknown"}
                  </div>
                  <div className="purchasehistory-product-price">
                    {product.price.toLocaleString()}원
                  </div>
                </div>

                <div className="purchasehistory-a4">
                  {reviewStatus[product._id] ? (
                    <button className="purchasehistory-review-btn" disabled>
                      작성 완료
                    </button>
                  ) : (
                    <button
                      onClick={() => handleReviewWrite(product._id)}
                      className="purchasehistory-review-btn"
                    >
                      후기 작성
                    </button>
                  )}
                  <button onClick={() => {alert("본 상품은 주문 취소 불가한 상품 입니다.")}} className="purchasehistory-cancleorder-btn">
                    주문 취소
                  </button>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}

export default Purchasehistory;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Cart() {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state) => state.user);
  const [productDetails, setProductDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const goBack = () => navigate("/");

  const fetchCartData = async () => {
    try {
      const res = await fetch(
        `https://us-central1-stories-e89af.cloudfunctions.net/start/api/cart/get/${currentUser._id}`
      );
      const data = await res.json();

      const productIds = data.products.map((product) => product.product_id);
      const fetchProductDetails = productIds.map((id) =>
        fetch(
          `https://us-central1-stories-e89af.cloudfunctions.net/start/api/listing/cart/get/${id}`
        ).then((res) => {
          if (!res.ok) {
            return null; // 응답이 성공적이지 않으면 null 반환
          }
          return res.json();
        })
      );

      const details = await Promise.all(fetchProductDetails);

      // null이 아닌 상품만 필터링
      setProductDetails(details.filter((detail) => detail !== null));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("데이터를 불러오기 실패", error);
    }
  };

  console.log(productDetails);

  const deleteProduct = async (productId) => {
    // 삭제할 상품을 즉시 상태에서 제거
    const updatedProductDetails = productDetails.filter(product => product._id !== productId);
    setProductDetails(updatedProductDetails);
  
    try {
      const res = await fetch(
        `https://us-central1-stories-e89af.cloudfunctions.net/start/api/cart/delete/${currentUser._id}/${productId}`,
        {
          method: "DELETE",
        }
      );
  
      if (!res.ok) {
        const errorData = await res.json();
        console.error("삭제 요청 실패:", errorData);
        // 만약 삭제 실패 시, 다시 상태를 원래대로 복구
        setProductDetails(productDetails); // 상태 복구
      }
    } catch (error) {
      console.error("삭제 요청에 실패했습니다.", error);
      // 실패 시 상태 복구
      setProductDetails(productDetails); // 상태 복구
    }
  };
  

  useEffect(() => {
    fetchCartData();
  }, [currentUser._id]);

  const totalPrice = productDetails.reduce((sum, product) => {
    // 상품이 유효하고 가격이 정의되어 있는지 확인
    const priceString = product?.price || "0";
    // 가격을 숫자로 변환
    const price = parseFloat(priceString.replace(/[^0-9.-]+/g, "")) || 0;
    return sum + price;
  }, 0);

  // 숫자를 지역화된 문자열로 변환
  const formattedTotalPrice = totalPrice.toLocaleString("ko-KR", {
    currency: "KRW",
  });

  const handleCheckout = () => {
    if (productDetails.length > 0) {
      navigate("/cartcheckout");
    }
  };

  return (
    <div className="CartGrid">
      <div className="cart-nav-container">
        <img onClick={goBack} src="back.svg" className="backicon" />
        <div className="cart-text">장바구니</div>
      </div>

      <div className="cart-product-container">
        {isLoading
          ? Array.from({ length: 10 }).map((_, index) => (
              <div className="cart-product">
                <div className="cart-a1">
                  <div className="skeleton skeleton-cart-product-thumbnail"></div>
                </div>
                <div className="cart-a2">
                  <div className="skeleton skeleton-cart-product-name"></div>
                  <div className="skeleton skeleton-cart-who-wrote-this"></div>
                  <div className="skeleton skeleton-cart-product-price"></div>
                </div>
                <div className="cart-a3"></div>
              </div>
            ))
          : productDetails.map((product, index) => (
              <div className="cart-product" key={index}>
                <div className="cart-a1">
                  <div className="cart-product-thumbnail">
                    <img
                      src={product.imageurl}
                      className="cart-product-thumbnail"
                    />
                  </div>
                </div>

                <div className="cart-a2">
                  <div className="cart-product-name">{product.personname}</div>
                  <div className="cart-who-wrote-this">
                    {product.brandname}
                  </div>
                  <div className="cart-product-price">{product.price}원</div>
                </div>

                <div
                  className="cart-a3"
                  onClick={() => deleteProduct(product._id)}
                >
                  <img src="closeicon2.svg" className="cart-closeicon" />
                </div>
              </div>
            ))}

        <div className="checkout-container">
          <div className="total-container">
            <div className="total">합계</div>
            <div className="total-price">{formattedTotalPrice}원</div>
          </div>
          <button onClick={handleCheckout} className="continue-checkout">
            계속 결제하기
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cart;

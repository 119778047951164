import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function Review() {
  const navigate = useNavigate(); // useNavigate 훅 사용
  const [reviewData, setReviewData] = useState([]);
  const [reviewSortModal, setReviewSortModal] = useState(false);
  const [selectedSort, setSelectedSort] = useState("최신순"); // 기본값은 최신순
  const sortOptions = ["최신순", "높은 평점순", "낮은 평점순"];

  const goBack = () => {
    navigate(-1); // 이전 페이지로 이동
  };

  const params = useParams();

  const toggleModal = () => {
    setReviewSortModal(prevState => !prevState);
  };

  useEffect(() => {
    const fetchGetReview = async () => {
      const res = await fetch(
        `https://us-central1-stories-e89af.cloudfunctions.net/start/api/review/detail/get/${params.id}`,
        {
          credentials: "include",
        }
      );
      const data = await res.json();
      setReviewData(data.reviews);
    };
    fetchGetReview();
  }, []);

  const handleSortOptionClick = (option) => {
    setSelectedSort(option);
    setReviewSortModal(false); // 옵션 선택 시 모달 닫기
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // 모달이 열려있고, 클릭한 곳이 모달 내부가 아닐 때 모달 닫기
      if (
        reviewSortModal &&
        !event.target.closest(".review-sort-modal") &&
        !event.target.closest(".review-filter")
      ) {
        setReviewSortModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [reviewSortModal]);

  // 리뷰 데이터 정렬 로직
  const sortedReviewData = [...reviewData].sort((a, b) => {
    if (selectedSort === "최신순") {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (selectedSort === "높은 평점순") {
      return b.rating - a.rating;
    } else if (selectedSort === "낮은 평점순") {
      return a.rating - b.rating;
    }
    return 0;
  });

  return (
    <div>
      <div className="review-nav-container">
        <img onClick={goBack} src="/back.svg" className="backicon" />
      </div>
      <div className="review-container1">
        <div className="review-container1-1">
          <img className="review-bigonestar" src="/bigonestar.svg" />
          {reviewData.length > 0 ? (
            <div className="review-score">
              {(
                reviewData.reduce((total, review) => total + review.rating, 0) /
                reviewData.length
              ).toFixed(1)}
            </div>
          ) : (
            <div className="review-not-review">리뷰가 존재하지 않습니다.</div>
          )}
        </div>
      </div>
      <div className="review-container2">
        <div className="review-container2-1">
          <div className="review-number-of-reviews">
            후기 {reviewData.length}개
          </div>
        </div>
        <div className="review-container2-2">
          {reviewSortModal ? (
            <div className="review-sort-modal">
              {sortOptions.map((option) => (
                <div
                  key={option}
                  className={`sort-option ${
                    selectedSort === option ? "selected" : ""
                  }`}
                  onClick={() => handleSortOptionClick(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          ) : null}
          <div
            onClick={toggleModal}
            className="review-filter"
          >
            {selectedSort}
          </div>
          <img className="review-dropdown" src="/dropdown.svg" />
        </div>
      </div>

      {sortedReviewData.length > 0
        ? sortedReviewData.map((review, index) => (
            <div key={index} className="review-container3">
              <div className="review-container3-1">
                <img className="detail-profile-img" src={review.profileimg} />
                <div>{review.nickname}</div>
              </div>
              <div className="review-container3-2">
                <div className="detail-review-rating-star">
                  <img
                    src={
                      review.rating >= 1
                        ? "/bigstarfilled.svg"
                        : "/bigstarempty2.svg"
                    }
                  />
                  <img
                    src={
                      review.rating >= 2
                        ? "/bigstarfilled.svg"
                        : "/bigstarempty2.svg"
                    }
                  />
                  <img
                    src={
                      review.rating >= 3
                        ? "/bigstarfilled.svg"
                        : "/bigstarempty2.svg"
                    }
                  />
                  <img
                    src={
                      review.rating >= 4
                        ? "/bigstarfilled.svg"
                        : "/bigstarempty2.svg"
                    }
                  />
                  <img
                    src={
                      review.rating >= 5
                        ? "/bigstarfilled.svg"
                        : "/bigstarempty2.svg"
                    }
                  />
                </div>
                <div className="detail-review-dot"></div>
                <div className="detail-review-date">
                  {new Date(review.created_at).toLocaleDateString("ko-KR", {
                    year: "numeric",
                    month: "long",
                  })}
                </div>
              </div>
              <div className="review-container3-3">
                <div>{review.content}</div>
              </div>
            </div>
          ))
        : null}
    </div>
  );
}

export default Review;

import React, { useEffect } from "react";
import ChannelService from "./ChannelService"; // ChannelService 파일을 적절히 import
import { useNavigate } from "react-router-dom";

function Cs() {
  const navigate = useNavigate(); // useNavigate 훅 사용

  const goBack = () => {
    navigate("/profile"); // 이전 페이지로 이동
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    // ChannelService 스크립트 로드
    ChannelService.loadScript();

    // ChannelService 부트
    ChannelService.boot({
      pluginKey: "618952ab-0bff-462b-8344-5990c5b34786",
      customLauncherSelector: ".custom-button-1",
      hideChannelButtonOnBoot: true,
    });

    // 컴포넌트가 언마운트될 때 ChannelService 종료
    return () => {
      ChannelService.shutdown();
    };
  }, []); // 빈 배열을 넣어 한 번만 실행되도록 설정

  
  return (
    <div className="d">
      <div className="forgotpassword-nav-container">
        <img onClick={goBack} src="back.svg" className="backicon" />
      </div>
      <div class="custom-button-1">
        <div className="forgot-box">
            <img src="cssupport.svg" className="cssupporticon"/>
        </div>
      </div>
    </div>
  );
}

export default Cs;
